import gql from 'graphql-tag';

export const GET_IMAGES = gql`
  query GetImages($imageableObjectId: Int!, $imageableObjectType: String!) {
    images(
      imageableObjectId: $imageableObjectId
      imageableObjectType: $imageableObjectType
    ) {
      images {
        header {
          imagePurpose
          ratio1x1 {
            id
            ratio
            filename
            fileLocation
          }
          ratio4x1 {
            id
            ratio
            filename
            fileLocation
          }
          ratio4x3 {
            id
            ratio
            filename
            fileLocation
          }
          ratio4x5 {
            id
            ratio
            filename
            fileLocation
          }
          ratio5x4 {
            id
            ratio
            filename
            fileLocation
          }
          ratio16x9 {
            id
            ratio
            filename
            fileLocation
          }
        }
        promoHeader {
          imagePurpose
          ratio5x4 {
            id
            ratio
            filename
            fileLocation
          }
          ratio16x9 {
            id
            ratio
            filename
            fileLocation
          }
        }
        partnerPromotion {
          imagePurpose
          ratio16x9 {
            id
            ratio
            filename
            fileLocation
          }
        }
      }
    }
  }
`;
